// color variants
@import "themes-vars.module.scss";

// third-party
@import "~react-perfect-scrollbar/dist/css/styles.css";

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
  z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
  margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.borderRadios10 {
  border-radius: 10px;
}
.scrollbar-container {
  .ps__rail-y {
    &:hover > .ps__thumb-y,
    &:focus > .ps__thumb-y,
    &.ps--clicking .ps__thumb-y {
      background-color: $grey500;
      width: 5px;
    }
  }
  .ps__thumb-y {
    background-color: $grey500;
    border-radius: 6px;
    width: 5px;
    right: 0;
  }
}
// .rdg-cell
.rdg-cell-frozen {
  line-height: 30px !important;
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
  &.ps--active-y > .ps__rail-y {
    width: 5px;
    background-color: transparent !important;
    z-index: 999;
    &:hover,
    &.ps--clicking {
      width: 5px;
      background-color: transparent;
    }
  }
  &.ps--scrolling-y > .ps__rail-y,
  &.ps--scrolling-x > .ps__rail-x {
    opacity: 0.4;
    background-color: transparent;
  }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
  50% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  0%,
  20%,
  53%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -5px, 0);
  }
  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -7px, 0);
  }
  80% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  90% {
    transform: translate3d(0, -2px, 0);
  }
}

@keyframes slideY {
  0%,
  50%,
  100% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-10px);
  }
  75% {
    transform: translateY(10px);
  }
}

@keyframes slideX {
  0%,
  50%,
  100% {
    transform: translateX(0px);
  }
  25% {
    transform: translateX(-10px);
  }
  75% {
    transform: translateX(10px);
  }
}

// ==============================|| TABLE ||============================== //

.table-container {
  position: relative;
  max-height: 600px;
  width: 100%;
  overflow: scroll;

  table {
    border-collapse: collapse;
    font-family: helvetica;
    caption-side: top;
    text-transform: capitalize;
  }

  td,
  th {
    border: 1px solid;
    padding: 10px;
    // min-width: 200px;
    background: white;
    box-sizing: border-box;
    text-align: center;
    &.max {
      background: rgb(186 247 190);
    }
    &.min {
      background: rgb(255 226 226);
    }
  }


  th {
    box-shadow: 0 0 0 1px black;
  }

  thead th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    // z-index: 2;
    background: hsl(22, 14%, 89%);
    text-align: center;
  }

  thead th:first-child {
    left: 0;
    z-index: 3;
  }

  tbody {
    overflow: scroll;
    height: 200px;
  }

  /* MAKE LEFT COLUMN FIXEZ */
  tr > :first-child {
    position: -webkit-sticky;
    position: sticky;
    background: hsl(22, 14%, 89%);
    left: 0;
    text-align: center;
  }
}
